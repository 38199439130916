import builder from '@builder.io/react'
import { Button } from 'atomic'
import { ButtonType } from 'atomic/components/atoms/button/Button'
import { ProductType } from 'components/builder_components/page_components/page_hero/layouts/product_hero/ProductHero'
import { Link } from 'components/link/Link'
import useAppLinks from 'hooks/useAppLinks'
import { useCmsOrTranslation } from 'hooks/useSafeTranslation'
import { trackOutboundLink } from 'lib/gtag'
import { useRouter } from 'next/router'
import React, { AllHTMLAttributes } from 'react'
import { isAndroid, isIOS } from 'react-device-detect'
import { LocaleKey } from 'types/LokalizeKeys'

type StartCreatingButtonProps = Omit<
  AllHTMLAttributes<HTMLButtonElement>,
  'content' | 'translate' | 'color' | 'background' | 'size' | 'width' | 'cursor'
> & {
  productType: Extract<ProductType, 'book' | 'print'>
  buttonText: string
  defaultLocalKey: LocaleKey
  type?: ButtonType
  size?: 'xs' | 's' | 'm' | 'l'
  style?: React.CSSProperties
  elevation?: boolean
  children?: React.ReactNode
}

const StartCreatingButton: React.FC<StartCreatingButtonProps> = ({
  productType,
  buttonText,
  defaultLocalKey,
  type = 'primary',
  size = 'm',
  elevation = false,
  children,
  ...props
}): JSX.Element => {
  const router = useRouter()
  const { appStoreUrl, googlePlayUrl } = useAppLinks()
  const { cmsOrLokalise } = useCmsOrTranslation()
  const btnText = cmsOrLokalise(buttonText, defaultLocalKey)
  if (isAndroid || isIOS) {
    const url = isAndroid ? googlePlayUrl : appStoreUrl
    return (
      <a
        href={url}
        onClick={() => {
          trackOutboundLink(url)
          builder.trackConversion()
        }}
      >
        <Button variant={type} elevation={elevation} size={size} marginBottom="xl" {...props}>
          {children ? children : btnText}
        </Button>
      </a>
    )
  }

  return (
    <Link
      onClick={() => {
        builder.trackConversion()
      }}
      href={`https://app.onceupon.photo/authentication/signinAnonymously/?productType=${productType}&lang=${router.locale}`}
    >
      <Button variant={type} elevation={elevation} size={size} marginBottom="xl" {...props}>
        {children ? children : btnText}
      </Button>
    </Link>
  )
}

export default StartCreatingButton
