import log from 'utils/logger'
import { GetStaticPaths, GetStaticProps, GetStaticPropsContext, GetStaticPropsResult } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { FC } from 'react'
import { AppStoreData } from 'types/AppStoreData'
import Layout from '../components/Layout'
import Page from '../components/Page'
import { FooterData, NavigationHeader, PageAlternateLink, TopBanner } from '../context/LinkContext'
import ChristmasDeliveryDate from '../types/ChristmasDeliveryDates'
import JobylonJob from '../types/JobylonJob'
import { builder } from '@builder.io/react'
import { BuilderContent } from '@builder.io/sdk'
import { ExtendendBuilderContent } from 'types/BuilderTypes'
import {
  CmsPath,
  PathParam,
  aggregateBuilderPaths,
  getAllBlogArticles,
  getAllBuilderPages,
  getFooterData,
  getNavHeaders,
  getOriginalContentId,
  getPageAlternatesForBuilder,
  getSlugForOriginalVariant,
  getTopBanners,
} from 'utils/cms-helper'
import { getAppStoreData } from 'utils/get-appstore-data'
import { getJobList } from 'utils/get-job-list'
import { getChristmasDates } from 'utils/get-christmas-dates'

type PropTypes = {
  builderContent?: BuilderContent
  pageAlternatesForBuilder?: PageAlternateLink[]
  preview?: boolean
  jobs?: JobylonJob[]
  appStoreData?: AppStoreData
  christmasDeliveryDates?: ChristmasDeliveryDate
  navHeaders?: NavigationHeader[]
  topBanners?: TopBanner[]
  footerData?: FooterData
}

const Slug: FC<PropTypes> = ({ appStoreData, builderContent, pageAlternatesForBuilder, navHeaders, topBanners, footerData }) => {
  const pageAlternates: PageAlternateLink[] = pageAlternatesForBuilder

  return (
    <Layout pageAlternates={pageAlternates} navHeaders={navHeaders} topBanners={topBanners} footerData={footerData}>
      <Page builderContent={builderContent} appStoreData={appStoreData} pageAlternatesForBuilder={pageAlternatesForBuilder} />
    </Layout>
  )
}

const prodEnvironment = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'

// Default cache in production. No cache in development
const builderCache = prodEnvironment ? {} : { cacheSeconds: 0, staleCacheSeconds: 0 }
const builderLimit = 50
export const getStaticProps: GetStaticProps = async (context: GetStaticPropsContext): Promise<GetStaticPropsResult<PropTypes>> => {
  let dynamicSlug = context.params.slug || ['home']
  const language = context.locale.substring(0, 2)
  const region = context.locale.substring(3).toUpperCase()
  let urlPath = ''
  if (Array.isArray(dynamicSlug)) {
    urlPath = '/' + dynamicSlug.join('/')
    dynamicSlug = dynamicSlug.join('/')
  }

  let builderContent: ExtendendBuilderContent = null
  let pageAlternatesForBuilder: PageAlternateLink[] = null
  let slugForOriginalVariantOfCurrentPage: string = null
  const localeLowercase = `${language}-${region.toLowerCase()}`
  const locale = region === 'CA' ? `${language}-${region.toUpperCase()}` : localeLowercase
  // look for the slug/page in Builder.io
  try {
    builderContent = await builder
      .get('page', {
        ...builderCache,
        userAttributes: { urlPath, locale },
        options: { includeRefs: true, locale: localeLowercase, includeUnpublished: !prodEnvironment },
      })
      .toPromise()

    if (builderContent) {
      const originalContentId = getOriginalContentId(builderContent)
      const builderPageInAllLocales = (await builder.getAll('page', {
        ...builderCache,
        options: { includeRefs: true, noTargeting: true, includeUnpublished: !prodEnvironment },
        query: {
          $or: [
            {
              'data.reference.id': originalContentId,
            },
            {
              id: originalContentId,
            },
          ],
        },
      })) as ExtendendBuilderContent[]

      pageAlternatesForBuilder = getPageAlternatesForBuilder({ content: builderPageInAllLocales, originalContentId })
      slugForOriginalVariantOfCurrentPage = getSlugForOriginalVariant({ content: builderPageInAllLocales, originalContentId })
    }
  } catch (err) {
    log.error('BUILDER ERROR', err)
  }

  let jobData = null
  try {
    if (builderContent) {
      jobData = await getJobList(slugForOriginalVariantOfCurrentPage)
    }
  } catch (err) {}

  const navHeaders = await getNavHeaders(builderCache, locale)
  const topBanners = await getTopBanners(builderCache, locale)
  const footerData = await getFooterData(builderCache, locale)

  if (!builderContent) {
    return {
      notFound: true,
      revalidate: prodEnvironment ? 600 : 600,
    }
  }

  return {
    props: {
      builderContent: builderContent || null,
      pageAlternatesForBuilder: pageAlternatesForBuilder,
      preview: context.preview || false,
      jobs: jobData,
      appStoreData: await getAppStoreData(),
      christmasDeliveryDates: slugForOriginalVariantOfCurrentPage?.includes('christmas-delivery')
        ? await getChristmasDates(language, region, 'christmas-delivery')
        : null,
      ...(await serverSideTranslations(`${language}-${region}`, ['common'])),
      navHeaders: navHeaders ?? null,
      topBanners: topBanners ?? null,
      footerData: footerData ?? null,
    },
    revalidate: prodEnvironment ? 600 : 600,
  }
}
export const getPathsFromBuilder = async (): Promise<CmsPath> => {
  const fields = 'data.url,query'
  const builderPages_page = await getAllBuilderPages({
    limit: builderLimit,
    fields,
    builderCache,
    contentType: 'page',
    includeUnpublished: !prodEnvironment,
  })

  const builderPages_blogArticle = await getAllBlogArticles(builderCache, !prodEnvironment)
  const builderPages = [...builderPages_page, ...builderPages_blogArticle]

  const reducedPages = builderPages.reduce(aggregateBuilderPaths, [] as PathParam[])
  const afterReducedPages = reducedPages?.map((obj) => {
    const urls = obj.url.slice(1).split('/')
    return { params: { slug: [...urls] }, locale: obj.locale?.toLowerCase() ?? 'en-gb' }
  })

  return afterReducedPages
}
export const getStaticPaths: GetStaticPaths = async () => {
  if (!prodEnvironment) {
    return {
      paths: [],
      fallback: 'blocking',
    }
  }

  const builderPaths = await getPathsFromBuilder()
  const allPaths = [...builderPaths]
  return {
    paths: allPaths,
    fallback: 'blocking',
  }
}
export default Slug
