import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { FC, useEffect } from 'react'
import { AppStoreData } from 'types/AppStoreData'
import { PageAlternateLink, useLink } from '../context/LinkContext'
import msClarity from '../lib/ms-clarity'
import { BuilderContent } from '@builder.io/sdk'
import { BuilderComponent } from '@builder.io/react'
import { themeVars } from 'atomic/styles/theme.css'
import RelatedPostsPages from 'components/related_posts_pages/RelatedPostsPages'
import StickyButton from './builder_components/sticky_button/StickyButton'
import { useCookieContext } from 'context/CookieProvider'

type PropTypes = {
  appStoreData?: AppStoreData
  builderContent?: BuilderContent
  pageAlternatesForBuilder?: PageAlternateLink[]
}

type MetaInfo = {
  title?: string
  description?: string
  noindex?: boolean
  open_graph_image?: {
    filename: string
  }
}

const Page: FC<PropTypes> = ({ builderContent, appStoreData, pageAlternatesForBuilder }) => {
  const { consentAnalytical } = useCookieContext()
  const router = useRouter()
  const { getCanonicalUrl } = useLink()

  const previewEnvironment = process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview'
  const prodEnvironment = process.env.NODE_ENV === 'production'

  const pageId = builderContent?.data?.id

  const renderBuilderContent = (content: BuilderContent): JSX.Element => {
    const related = content?.data?.relatedposts?.map((relatedPost: { post: { id: string } }) => relatedPost?.post?.id)
    return (
      <>
        <BuilderComponent locale={router.locale} model={'page'} content={content} />
        <RelatedPostsPages relatedPosts={related} />
      </>
    )
  }

  const setFirstImgAltToH1TextIfNotAlreadySet = (): void => {
    const h1 = document.getElementsByTagName('h1')[0]
    const image = document.getElementsByTagName('img')[0]
    if (h1 && image && image.alt?.length === 0) {
      image.alt = h1.innerText
    }
  }

  useEffect(() => {
    setFirstImgAltToH1TextIfNotAlreadySet()
  }, [])

  let metaObject: MetaInfo = {
    // always set noindex to true in dev and preview environment
    noindex: !prodEnvironment || previewEnvironment ? true : (builderContent?.data?.noindex ?? false),
    description: builderContent?.data?.description ?? '',
    title: builderContent?.data?.title ?? '',
    open_graph_image: builderContent?.data?.ogimage ? { filename: builderContent?.data?.ogimage } : undefined,
  }

  // implemented because Facebook needs a og:locale meta-tag with underscore instead of dash and capital variant, i.e. 'en-gb' will become 'en_GB'
  const localeParts = router.locale.split('-')
  const localeWithUnderscore = localeParts.length === 2 ? localeParts[0] + '_' + localeParts[1].toUpperCase() : router.locale

  if (router.asPath.indexOf('/stories-old') === 0) {
    metaObject = {
      noindex: true,
      description: '',
      title: '',
      open_graph_image: undefined,
    }
  }

  const title = metaObject?.title
  const canonicalUrl = getCanonicalUrl(router.asPath, router.locale, router.defaultLocale)

  return (
    <>
      <Head>
        <title>{title}</title>
        {metaObject.noindex ? <meta name="robots" content="noindex" /> : <meta name="robots" content="index, follow" />}
        <meta name="description" content={metaObject.description} />
        <meta name="twitter:description" content={metaObject.description} />
        <meta property="og:description" content={metaObject.description} />
        <meta property="og:title" content={metaObject.title} />
        <meta name="twitter:title" content={metaObject.title} />
        <meta property="og:image" content={metaObject.open_graph_image?.filename} />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content={localeWithUnderscore} />
        {appStoreData && pageId === 'photobooks' && (
          <Script
            id="app-store-data"
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(appStoreData) }}
            key="product-jsonld"
          />
        )}
        <link rel="canonical" href={canonicalUrl} />
        {pageAlternatesForBuilder?.map((alt, index) => (
          <link rel="alternate" hrefLang={alt.hreflang.toLowerCase()} href={alt.href.toLowerCase()} key={`${alt.href}${index}`} />
        ))}
      </Head>

      {consentAnalytical && prodEnvironment && <Script id="ms-clarity">{msClarity}</Script>}
      <main
        style={{
          backgroundColor: builderContent?.data?.backgroundColor?.value.data.color ? builderContent.data.backgroundColor?.value.data.color : null,
          margin: builderContent?.data?.backgroundColor?.value.data.color ? themeVars.spaces.s : null,
          position: 'relative',
        }}
      >
        {builderContent ? renderBuilderContent(builderContent) : <></>}{' '}
        {builderContent && builderContent.data?.stickyButton && <StickyButton settings={builderContent.data?.stickyButtonSettings} />}
      </main>
    </>
  )
}

export default Page
