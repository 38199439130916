import { Link } from 'components/link/Link'
import React, { useEffect, useState } from 'react'
import * as css from './StickyButton.css'
import builder from '@builder.io/react'
import Button from 'atomic/components/atoms/button/Button'
import { themeVars } from 'atomic/styles/theme.css'
import PlusIcon from 'components/icons/PlusIcon'
import StartCreatingButton from 'components/start_creating_button/StartCreatingButton'
import { defaultCreateButtonKey } from 'types/LokalizeKeys'

type ButtonSettings = {
  label: string
  size: 'xs' | 's' | 'm' | 'l'
  link: string
  linkToApp: boolean
  productType?: 'book' | 'print'
  type: 'cta' | 'primary' | 'secondary'
  elevation: boolean
}

type StickyButtonProps = {
  settings: ButtonSettings
}

const StickyButton: React.FC<StickyButtonProps> = ({ settings }) => {
  const [isHovered, setIsHovered] = useState(false)
  const [scrollY, setScrollY] = useState(0)

  useEffect(() => {
    const handleScroll = (): void => {
      setScrollY(window.scrollY)
    }
    handleScroll()
    window.addEventListener('scroll', handleScroll)
    return (): void => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <>
      {!settings.linkToApp && (
        <>
          <Link
            href={settings?.link}
            onClick={() => builder.trackConversion()}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className={css.hideOnMobile}
            aria-label={'Start creating'}
          >
            <Button
              variant={settings.type ?? 'cta'}
              size={settings.size ?? 'l'}
              elevation={settings.elevation ?? false}
              style={{
                opacity: scrollY <= 700 ? 0 : 1,
                position: 'fixed',
                bottom: '1.8em',
                right: '1.8em',
                transition: 'all ease 0.8s',
                zIndex: 5,
                width: scrollY >= 1300 && !isHovered ? themeVars.spaces.xl : 'inherit',
                paddingInline: scrollY >= 1300 && !isHovered ? 0 : themeVars.spaces.l,
              }}
              aria-label={'Start creating'}
            >
              {scrollY >= 1300 && !isHovered ? <PlusIcon color={settings.type === 'primary' ? '#fff' : '#222'} /> : (settings.label ?? 'Button')}
            </Button>
          </Link>

          <Link
            href={settings.link}
            onClick={() => builder.trackConversion()}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className={css.showOnMobile}
            aria-label={'Start creating'}
          >
            <Button
              variant={settings.type ?? 'cta'}
              size={settings.size ?? 'l'}
              elevation={settings.elevation ?? false}
              style={{
                opacity: scrollY <= 500 ? 0 : 1,
                position: 'fixed',
                bottom: '1em',
                right: '1em',
                transition: 'all ease 0.8s',
                width: themeVars.spaces.xl,
                paddingInline: 0,
                zIndex: 5,
              }}
              aria-label={'Start creating'}
            >
              <PlusIcon color={settings.type === 'primary' ? '#fff' : '#222'} />
            </Button>
          </Link>
        </>
      )}
      {settings.linkToApp && (
        <>
          <StartCreatingButton
            className={css.hideOnMobile}
            productType={settings?.productType}
            buttonText={settings?.label}
            type={settings?.type}
            size={settings?.size}
            elevation={settings?.elevation}
            defaultLocalKey={defaultCreateButtonKey}
            style={{
              opacity: scrollY <= 700 ? 0 : 1,
              position: 'fixed',
              bottom: themeVars.spaces['xxxs'],
              right: '1.8em',
              transition: 'all ease 0.8s',
              zIndex: 5,
              width: scrollY >= 1300 && !isHovered ? themeVars.spaces.xl : 'inherit',
              paddingInline: scrollY >= 1300 && !isHovered ? 0 : themeVars.spaces.l,
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {scrollY >= 1300 && !isHovered ? <PlusIcon color={settings.type === 'primary' ? '#fff' : '#222'} /> : settings?.label}
          </StartCreatingButton>

          <StartCreatingButton
            className={css.showOnMobile}
            productType={settings?.productType}
            buttonText={settings?.label}
            type={settings?.type}
            size={settings?.size}
            elevation={settings?.elevation}
            defaultLocalKey={defaultCreateButtonKey}
            style={{
              opacity: scrollY <= 500 ? 0 : 1,
              position: 'fixed',
              bottom: 0,
              right: '1em',
              transition: 'all ease 0.8s',
              width: themeVars.spaces.xl,
              paddingInline: 0,
              zIndex: 5,
            }}
          >
            <PlusIcon color={settings.type === 'primary' ? '#fff' : '#222'} />
          </StartCreatingButton>
        </>
      )}
    </>
  )
}

export default StickyButton
